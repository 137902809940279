<template>
  <div class="newsDetails">
    <div class="banner">
      <img src="@/assets/news/banner.png" alt="" />
      <span>中飞科技新闻动态</span>
    </div>
    <div class="content">
      <div class="content_left">
        <p>
          {{ info.title }}
        </p>
        <p>发布时间：{{ info.createTime }}</p>
        <!-- <div>
          2020年8月21日，由中华医学会、中华医学会影像技术分会主办，上海健康医学院、上海市医学会、上海市医学会医学影像技术分会、中华医学会影像技术分会教育专业委员会、中华医学会影像技术分会继续教育专业委员会承办，人民卫生出版社协办，山东中飞科技有限公司全程独家线上技术支持的第七届“泰山杯”全国医学影像技术专业大学生（本科）实践技能大赛、全国医学影像技术专业（本科）教育教学研讨会、第三届全国医学影像技术专业青年教师微课教学大赛、上海市医学会医学影像技术分会学术年会暨医学影像技术学上海论坛在上海健康医学院隆重举行。
          <img src="@/assets/news/photograph3.png" alt="" />
          山东中飞科技有限公司作为本次大赛唯一指定合作单位，全程、独家提供了专业全面的技术支持，包括：自主研发的直播平台、直播互动，多机位切换；专家讲座、作品展示、微课示范、专家示范视频的直播和录播等。
        </div> -->
        <div v-html="info.content"></div>
      </div>
      <div class="content_right">
        <p class="title">最新新闻</p>
        <ul>
          <li
            v-for="item in newsList"
            :key="item.newsId"
            :class="{ checkd: item.newsId === id }"
            @click="toPath(item)"
          >
            <span class="dot"></span>
            <p>{{ item.title }}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { newsInfo } from "@/api/web";
export default {
  name: "",
  data() {
    return {
      newsList: [
        {
          text: "济南市医养健康产业协会第二届第二次理事会议在济南召开",
          id: 0,
        },
        {
          text: "喜报! 山东中飞科技有限公司顺利通过国家高新技术企业认定",
          id: 1,
        },
        {
          text: "济南市医养健康产业协会第二届第二次理事会议在济南召开",
          id: 2,
        },
      ],
      info: {},
      id: null,
    };
  },
  created() {
    this.getNewsInfo();
  },
  methods: {
    async getNewsInfo() {
      const { data } = await newsInfo({ newsId: this.$route.params.newsId });
      this.info = data.news;
      this.id = data.news.newsId;
      this.newsList = data.newsList;
      console.log(data);
    },
    toPath(item) {
      console.log(item);
      this.$router.push(`/newsDetails/${item.newsId}`);
      this.$router.go(0);
    },
  },
};
</script>

<style scoped lang="scss">
.newsDetails {
  .banner {
    position: relative;
    img {
      width: calc(100% + 2px);
      margin-left: -1px;
    }
    span {
      position: absolute;
      top: 242px;
      left: 235px;
      font-size: 36px;
      color: #ffffff;
    }
  }
  .content {
    display: flex;
    justify-content: space-between;
    width: 1460px;
    min-height: 600px;
    margin: 0 auto;
    margin-top: 51px;
    // margin-top: -190px;
    margin-bottom: 200px;
    // margin-bottom: -100px;
    .content_left {
      width: 100%;
      p {
        &:nth-of-type(1) {
          text-align: center;
          font-size: 28px;
          word-break: break-all;
        }
        &:nth-of-type(2) {
          text-align: center;
          margin: 40px 0;
          font-size: 18px;
          font-family: Microsoft YaHei;
          color: #353535;
        }
      }
      div {
        line-height: 42px;
        font-size: 17.5px;
        word-break: break-all;

        img {
          display: block;
          margin: 40px auto;
        }
      }
    }
    .content_right {
      margin-left: 120px;
      max-width: 550px;
      .title {
        width: 404px;
        padding-bottom: 27px;
        margin-bottom: 27px;
        border-bottom: 2px solid #949494;
        line-height: 48px;
        color: #181818;
        font-size: 28px;
      }
      ul {
        li {
          margin-bottom: 50px;
          padding-left: 42px;
          cursor: pointer;
          //   .dot {
          //     display: inline-block;
          //     width: 14px;
          //     height: 14px;
          //     background: #979797;
          //     border-radius: 50%;
          //     margin-right: 18px;
          //   }
          p {
            position: relative;
            display: inline;
            font-size: 18px;
            color: #363636;
            line-height: 36px;
            word-break: break-all;

            &::after {
              content: "";
              position: absolute;
              left: -30px;
              top: 10px;
              width: 14px;
              height: 14px;
              background: #979797;
              border-radius: 50%;
              margin-right: 18px;
            }
          }
        }
        .checkd {
          p {
            color: #26499d;
            &::after {
              background: #e99315;
            }
          }
        }
      }
    }
  }
}
::v-deep {
  .ql-video {
    display: block;
  }
}
</style>
